import { Injectable } from '@angular/core';
import { guidGenerator } from '../utils/id';

export type ToastLevel = 'info' | 'warn' | 'error';
export type Toast = { id: string; level: ToastLevel; title: string; message: string; };

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: Toast[] = [];

  constructor() {
    // any can stay for window
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    (window as any).toast = this;
  }

  info(title: string, message: string): void {
    this.show('info', title, message);
  }

  warn(title: string, message: string): void {
    this.show('warn', title, message);
  }

  error(title: string, message: string): void {
    this.show('error', title, message);
  }

  private show(level: ToastLevel, title: string, message: string) {
    const toast = { id: guidGenerator(), level, title, message };
    // Sometimes toast is shown after API call. wait a bit for overlay to be gone
    setTimeout(() => this.toasts.push(toast), 200);
    setTimeout(() => this.remove(toast), 5000);
  }

  remove(toast: Toast) {
    this.toasts = this.toasts.filter(t => t.id !== toast.id);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }
}
